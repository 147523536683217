$bgimages:   
  "hero-banner" "hero-banner.webp",
  "call-to-action" "call-to-action.webp",
  "inner-banner-options" "inner-banner-options.webp",
  "about" "about.webp",
  "mobileapp-development" "mobileapp-development.webp",
  "java-development" "java-development.webp",
  "database-design" "database-design.webp",
  "front-end-development" "front-end-development.webp",
  "analytics-business-intelligence" "analytics-business-intelligence.webp",
  "iot" "iot.webp",
  "blockchain" "blockchain.webp",
  "case-studies" "case-studies.webp",
  "software-development" "software-development.webp", 
  "api-development" "api-development.webp",
  "cloud-development" "cloud-development.webp",
  "web-design" "web-design.webp",
  "mobileapp-design" "mobileapp-design.webp",
  "ui-ux-design" "ui-ux-design.webp",
  "product-design" "product-design.webp",
  "logo-design" "logo-design.webp",
  "seo" "seo.webp",
  "social-media-marketing" "social-media-marketing.webp",
  "content-marketing" "content-marketing.webp",
  "ppc" "ppc.webp",
  "app-store" "app-store.webp",
  "bpm" "bpm.webp",
  "rpa" "rpa.webp",
  "technology-consulting" "technology-consulting.webp",
  "application-modernization" "application-modernization.webp",
  "bpa" "bpa.webp",
  "user-acceptance-testing" "user-acceptance-testing.webp",
  "regression-testing" "regression-testing.webp",
  "security-penetration-testing" "security-penetration-testing.webp",
  "automation-testing" "automation-testing.webp",
  "load-testing" "load-testing.webp",
  "technology" "technology.webp",
  "career" "career.webp",
  "contact-us" "contact-us.webp",
  "hero" "hero.webp",
  "footer-bg" "footer-bg.webp",
  "web-app" "web-app.webp",
  "mobile-app" "mobile-app.webp",
  "java-script" "java-script.webp",
  "software-call" "software-call.webp",
  "cloud-application" "cloud-application.webp",
  "api-poject" "api-poject.webp",
  "web-design-call" "web-design-call.webp",
  "mobile-app-design" "mobile-app-design.webp",
  "ui-ux" "ui-ux.webp",
  "logo-action" "logo-action.webp",
  "search-engine" "search-engine.webp",
  "social-media" "social-media.webp",
  "content-call" "content-call.webp",
  "pay-per-click" "pay-per-click.webp",
  "app-stores" "app-stores.webp",
  "bpm-consulting" "bpm-consulting.webp",
  "rpa-consulting" "rpa-consulting.webp",
  "technology-call" "technology-call.webp",
  "application-modernization-call" "application-modernization-call.webp",
  "business-process-automation" "business-process-automation.webp",
  "use-acceptance" "use-acceptance.webp",
  "regression-testing-call" "regression-testing-call.webp",
  "security-testing" "security-testing.webp",
  "automation-testing-call" "automation-testing-call.webp",
  "load-testing-call" "load-testing-call.webp",
  "portfilio" "portfilio.webp",
  "blazor-development" "blazor-development.webp",
  "dot-net-maui-development" "dot-net-maui-development.webp",
  "dot-net-development" "dot-net-development.webp",
  "laravel-development" "laravel-development.webp",
  "node-js-development" "node-js-development.webp",
  "react-js-development" "react-js-development.webp",
  "angular-js-development" "angular-js-development.webp",
  "Shape" "Shape.svg";
  
  

@each $name, $file in $bgimages {
  .bg-img-#{$name} {
    width: 100%;
    height: 100%;
    background-image: url("../img/bg/#{$file}");
    background-repeat: no-repeat;
    background-position: center;
   
  }
}