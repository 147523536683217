@import "../vendors/animate/aos";
@import "_libs/variables";
@import "node_modules/bootstrap/scss/bootstrap";


$bootstrap-icons-font-src: url("../fonts/bootstrap-icons.woff2") format("woff2"), url("../fonts/bootstrap-icons.woff") format("woff");
@import "node_modules/bootstrap-icons/font/bootstrap-icons";

@import "_app";

body {
    font-family: "Poppins", sans-serif;    
    font-size: 18px;
    font-weight: 300;
    line-height: 1.8;
}

/* navbar*/

.fixed-top {
    top: -40px;
    transform: translateY(40px);
    transition: transform .3s;
}

.navbar-nav {
    font-size: 16px;
}

.nav-link:hover {
    color: $primary !important;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

.like-us:hover {
    font-weight: bold;
    color: $primary;
    cursor: pointer;
}


.dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
    left: 0;
}

.dropdown-hover>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.list-group-item {
    border: 0px;
    font-size: 14px;

}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: rgb(244 67 54 / 13%);
    background-image: var(--bs-gradient);  
    
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #000000;
    
}

.nav-link {
    color: black;

}

.nav-link:hover {
    color: black !important;
    background: #f443360a;
    cursor: pointer !important;
    
}

.list-group-item .p {
    font-size: 1px !important;
}

//* Mobile Menu *//

.sidebar li .submenu {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
}



/* Home Cards */

.service .card:hover {

    background-color: $white;
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.2s;
    filter: drop-shadow(0px 0px 10px #edf0f2);
    z-index: 9000;


}

.service .card-body .card-text {
    transition: 0.8%;
    opacity: 100%;
    top: 100%;
}

.service .card-body:hover .card-text {
    transition: all 0.3s ease;
    opacity: 100;
    top: 0%;
}

.service .card-bg-dark {
    background: $danger !important;
    display: block;
    height: 100%;
}

.service .card-bg-dark:hover {
    background-color: $white !important;
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.2s;
    filter: drop-shadow(0px 0px 10px #edf0f2);
    z-index: 9000;
}

.title {
    font-size: $tital-font-size;
}

.zoom-in-zoom-out {

    animation: zoom-in-zoom-out 2s ease-out infinite;
}

.my-section {
    z-index: -9999;
    /* Adjust the value as needed */
}


@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}


//* Text Type animation*//

.text-animation {

    white-space: nowrap;
    overflow: hidden;
    animation: typewriter 4s steps(200) infinite alternate,
        blink 800ms steps(22) infinite normal;
    border-right: 2px solid black;
}

@keyframes typewriter {
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
}

@keyframes blink {
    from {
        border-color: black;
    }

    to {
        border-color: transparent;
    }
}

/* Button Animation */


button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

button.learn-more {
    width: 13rem;
    height: auto;
}

button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: white;
    border-radius: 1.625rem;
}

button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
}

button.learn-more .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}

button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid $info;
    border-right: 0.125rem solid $info;
    transform: rotate(45deg);
}

button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 2.0rem;
    color: white;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

button:hover .circle {
    width: 100%;
}

button:hover .circle .icon.arrow {
    background: $info;
    transform: translate(1rem, 0);
}

button:hover .button-text {
    color: $info;
}


/** buttom normal **/



.normal button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: $primary;
    border-radius: 1.625rem;
}

.normal button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: $primary;
}

.normal button.learn-more .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: black;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
}

.normal button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid white;
    border-right: 0.125rem solid white;
    transform: rotate(45deg);
}


.normal button:hover .circle .icon.arrow {
    background: white;
    transform: translate(1rem, 0);
}

.normal button:hover .button-text {
    color: white;
}

.normal button:hover .circle {
    width: 100%;
    background-color: black;
}



#signUpForm .form-header {
    gap: 5px;
    text-align: center;
    font-size: .9em;
}

#signUpForm .form-header .stepIndicator {
    position: relative;
    flex: 1;
    padding-bottom: 30px;
}

#signUpForm .form-header .stepIndicator.active {
    font-weight: 600;
}

#signUpForm .form-header .stepIndicator.finish {
    font-weight: 600;
    color: $secondary;
}

#signUpForm .form-header .stepIndicator::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 9;
    width: 20px;
    height: 20px;
    background-color: #e3e3e3;
    border-radius: 50%;
    border: 3px solid $dark;
}

#signUpForm .form-header .stepIndicator.active::before {
    background-color: #a7ede8;
    border: 3px solid $dark;
}

#signUpForm .form-header .stepIndicator.finish::before {
    background-color: $primary;
    border: 3px solid $dark;
}

#signUpForm .form-header .stepIndicator::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: #f3f3f3;
}

#signUpForm .form-header .stepIndicator.active::after {
    background-color: #a7ede8;
}

#signUpForm .form-header .stepIndicator.finish::after {
    background-color: $primary;
}

#signUpForm .form-header .stepIndicator:last-child:after {
    display: none;
}

#signUpForm input {
    padding: 15px 20px;
    width: 100%;
    font-size: 1em;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

#signUpForm input:focus {
    border: 1px solid #009688;
    outline: 0;
}

#signUpForm input.invalid {
    border: 1px solid #ffaba5;
}

#signUpForm .step {
    display: none;
}

#signUpForm .form-footer {
    overflow: auto;
    gap: 20px;
}

#signUpForm .form-footer button {
    background-color: $primary;
    border: 1px solid white !important;
    color: #ffffff;
    border: none;
    padding: 13px 30px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    flex: 1;
    margin-top: 5px;
}

#signUpForm .form-footer button:hover {
    opacity: 0.8;
}

#signUpForm .form-footer #prevBtn {
    background-color: #fff;
    color: $primary;
    border: 1px solid $primary !important;
}

/** Form **/

.form-control:focus {

    box-shadow: none;
}

.form-select:focus {

    box-shadow: none;
}


/** acodian **/

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: white !important;
    box-shadow: inset 0 calc(-0 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
    box-shadow: none !important;
}


.sticky-top {
    padding-top: 110px;
}


#particle-container {
    overflow: hidden;
    width: 100%;

    /* background-color:rgb(245, 245, 245); */
    position: relative;
}

.particle {
    position: absolute;
    border-radius: 50%;
}

.text-content {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
    /* Ensure text is above particles */
}

.bg-img-hero-banner {
    width: 100%;
    height: 600px;
    background-image: url(../img/bg/hero-banner.webp);
    background-repeat: no-repeat;
    background-position: center;
}

.inner-bg {
    width: 100%;
    height: 600px;
}


.home-about-img {
    z-index: -1000;
}

.home-count {
    margin-top: -130px;
}

.inner-home-count {
    margin-top: -130px;
}

.home-about {
    margin-top: -80px;
}

.tech {
    padding-bottom: 8rem !important;
}


//** marquee ** //

.marquee-left,
.marquee-right {
    height: 80px;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.marquee-left div,
.marquee-right div {
    display: flex;
    width: 200%;
    height: 80px;
    position: absolute;
    overflow: hidden;
}

.marquee-left div {
    animation: marquee-left 25s linear infinite;
}

.marquee-right div {
    animation: marquee-right 25s linear infinite;
}

.marquee-left span,
.marquee-right span {
    display: inline-block;
    padding: 0 2rem;
    white-space: nowrap;
}

@keyframes marquee-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes marquee-right {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0);
    }
}


//** Testimonial **//

.testimonial {
    .card-body {
        flex: 1 1 auto;
        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
        color: var(--bs-card-color);
        margin-top: -101px;
        background-color: #000000b7;
    }
}


/** Media Query **/

@media only screen and (max-width: 400px) {
    .display-6 {
        font-size: calc(1.250rem + 1.5vw);
        font-weight: 300;
        line-height: 1.2;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {

    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1003px !important;
    }
}

@media only screen and (max-width: 576px) and (min-width: 250px) {

    .text-animation {
        white-space: nowrap;
        overflow: hidden;
        animation: typewriter 6s steps(400) infinite alternate,
            blink 800ms steps(22) infinite normal;
        border-right: 2px solid black;
    }

    @keyframes typewriter {
        from {
            width: 0%;
        }

        to {
            width: 90%;
        }
    }

    @keyframes blink {
        from {
            border-color: black;
        }

        to {
            border-color: transparent;
        }
    }

}

@include media-breakpoint-between(lg, xl) {

    .text-animation {
        white-space: nowrap;
        overflow: hidden;
        animation: typewriter 6s steps(200) infinite alternate,
            blink 800ms steps(22) infinite normal;
        border-right: 2px solid black;
    }

    @keyframes typewriter {
        from {
            width: 0%;
        }

        to {
            width: 65%;
        }
    }

    @keyframes blink {
        from {
            border-color: black;
        }

        to {
            border-color: transparent;
        }
    }

}

@include media-breakpoint-only(lg) {
    .navbar-brand {
        width: 200px;
    }
}

@include media-breakpoint-between(xs, lg) {
    .sticky-top {
        padding-top: 5px;
    }
}

@include media-breakpoint-between(xs, sm) {
    .bg-img-hero-banner {
        width: 100%;
        height: 307px;
        background-image: url(../img/bg/hero-banner.webp);
        background-repeat: no-repeat;
        background-position: center;
    }
}

@include media-breakpoint-between(sm, md) {
    .inner-bg {
        width: 100%;
        height: 380px;
    }
}

@media (min-width: 250px) and (max-width: 579px) {
    .inner-bg {
        width: 100%;
        height: 374px;
    }
}



@include media-breakpoint-between(xs, xxl) {
    .home-count {
        margin-top: 0px;
    }
}

@include media-breakpoint-between(xs, lg) {
    .inner-home-count {
        margin-top: 0px;
    }
}

@include media-breakpoint-between(xs, lg) {
    .border-start {
        border-left: none !important;
    }
}


@include media-breakpoint-between(xs, sm) {
    .tech-slideshow {
        height: 60px;
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        font-size: 0;
        padding-top: 10px;

    }

    .mover-1 {
        height: 150px;
        width: max-content;
        position: absolute;
        overflow-x: hidden;
        top: 0;
        left: 0;

        animation: moveSlideshow 5s linear infinite;
    }

    .mover-1 img {
        display: inline-block;
        vertical-align: middle;
        width: 140px;
        margin: 0;
        padding-right: 20px;
    }

    @keyframes moveSlideshow {
        100% {
            transform: translateX(-250px);
        }
    }
}


@include media-breakpoint-between (xs, md) {

    .marquee-left,
    .marquee-right {
        height: 48px;
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .marquee-left div {
        animation: marquee-left 8s linear infinite;
    }

    .marquee-right div {
        animation: marquee-right 8s linear infinite;
    }
}

@include media-breakpoint-between (md, lg) {
    .inner-bg {
        width: 100%;
        height: 483px;
    }
}

@include media-breakpoint-between (md, lg) {
    .title {
        font-size:50px;
    }
}

.blog-text{
    font-size: 18px;
}
.blog-text span{
    font-size: 18px;
}
.blog-text h2 {
    font-size: 18px;
    font-weight :400;
}


#cookie {
    display: none;
}
#cookie-popup {
    position: fixed;
    bottom: 0;
    font-size: 16px;
    left: 13px;
    width: 350px;
    background: #001624;
    color: white;
    padding: 15px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1000;
    border-radius: 10px 10px 0px 0px;
}
#cookie-popup button {
    margin-top: 10px;
    padding: 8px 15px;
    // border: none;
    cursor: pointer;
    width: 100%;
}
#accept-cookies {
    background-color: #F94A22;
    color: white;
    border-radius: 5px;
}
#decline-cookies {
    border: 1px solid white;
    color: white;
    border-radius: 5px;
}