.rounded-circle {
    border-radius: 50% !important;
    width: 150px;
    height: 150px;
    z-index: -999;
}
.vertical-line{
    border-left: 1px solid;
    border-image: linear-gradient(to bottom, black, white, Black) 0 1;
}

#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 75px;
    // right: 20px;
    display: none;
  }

  .back-to-top {
    border-radius: 50% !important;    
}

#chatButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

.back-to-top {
  border-radius: 50% !important;    
}



.card-body{
    .footer{        
    font-size: 15.5px !important;
    }    
}


.Inquiry_inquiry-section{
    position: relative;
}

.Inquiry_inquiry-section:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 490px;
    background: #212121 url(../img/bg/footer-bg.webp) no-repeat 50%;
    background-size: cover;
    z-index: -1;
}

.chat-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;    
  }
  
  .chat-button {  
    bottom: 20px;
    right: 20px;  
    background-color: $primary;
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .extra-buttons {
    display: none;
    position: absolute;
    top: -252px; /* Adjust this value as needed */
    right: 20px;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .extra-buttons .option {    
    background-color: $primary;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    margin: 5px 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .option-a { background-color: #03a9f4; }
  .option-b { background-color: #ffb5d2; }
  .option-c { background-color: #8c9eff; }



.inquiry-sumbit-sucess{
    color: #018a58;
}

// social media//

/* for all social containers*/
.socialContainer {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: .3s;
  }
  /* instagram*/
  .containerOne:hover {
    background-color: #d62976;
    transition-duration: .3s;
  }
  /* twitter*/
  .containerTwo:hover {
    background-color: #00acee;
    transition-duration: .3s;
  }
  /* linkdin*/
  .containerThree:hover {
    background-color: #0072b1;
    transition-duration: .3s;
  }
  /*facebook*/
  .containerFour:hover {
    background-color: #1976D2;
    transition-duration: .3s;
  }
  
  .socialContainer:active {
    transform: scale(0.9);
    transition-duration: .3s;
  }
  
  .socialSvg {
    width: 17px;
  }
  
  .socialSvg path {
    fill: rgb(255, 255, 255);
  }
  
  .socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
  }
  
  @keyframes slide-in-top {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  



@include media-breakpoint-between(xs, xl) {
    .footer {
        .text-white{
            color: black !important;
        }
        h5{
            color: black !important;
        }
        .btn {
          color: white !important;
        }
    }
}


@include media-breakpoint-between(xl, xxl) {
    .Inquiry_inquiry-section:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 540px;
        background: #212121 url(../img/bg/footer-bg.webp) no-repeat 50%;
        background-size: cover;
        z-index: -1;
    }
}