/*! `Custom` Bootstrap 5 theme */
// @import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,700);

  // @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$headings-font-family:"Poppins", sans-serif;
$tital-font-size:60px;
$enable-grid-classes:true;
$primary:#FC431A;
$secondary:#001624;
$success:#dff2e9;
$danger:#F7F9FB;
$info:#00B9AB;
$warning:#687580;
$light:#4192FD;;
$dark:#ECECEC; 
$enable-gradients:true;
$spacer:1rem;
$border-width:1px;
$btn-border-radius:.25rem;

// Add SASS theme customizations here..